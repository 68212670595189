module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__eslint-plugin-_2gnek7awvnnq7h4lbjwql5o7my/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"Tempus Fugit","start_url":"/","icon":"src/images/icon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"95d8b169287e0fdcd1b38e662f3cec2f"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__eslint-plugin-j_3a5tvzdjduv3vbbuzqsya3flci/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-matomo@0.17.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__eslint-plugin-je_ce2ogdzlr2xn7ghtezs2k7f7o4/node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://stats.higherlearning.eu","siteUrl":"https://outpost.higherlearning.eu"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__eslint-plugin-jest@28.11.0_@typescript-eslin_k5zpmtmhzpgg7h34iujwiaputm/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
